<template>
  <div class="page">
    <div class="page-header">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="addItem"
        v-has="'add'"
      >{{ $t('group.addGroup') }}</el-button>
    </div>
    <div class="page-main">
      <div class="page-tools">
        <el-form :inline="true" :model="tabelSearch">
          <el-form-item v-has="'delete'">
            <el-button
              type="primary"
              icon="el-icon-plus iconfont icon-delete"
              @click="deleteItem()"
              :disabled="!tableSelection.length"
              >{{ $t('delete') }}
            </el-button>
          </el-form-item>
          <el-form-item class="page-tools-right keywords" v-has="'list'">
            <el-input
              v-model="tabelSearch.keywords"
              :placeholder="$t('group.keywordsPla')"
              prefix-icon="el-icon-search"
              clearable
              @change="getTabelData"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <table-pagination
        memory="group"
        ref="refTable"
        :height="pageTabelHeight"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        :optionMinWidth="120"
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
      >
        <!-- option -->
        <template slot="deviceNumber" slot-scope="scope">
          <span>{{ scope.row.deviceNumber }}</span>
          <i
            v-if="scope.row.deviceNumber"
            class="el-icon-warning-outline"
            @click="deviceDetails(scope.row)"
          ></i>
        </template>
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('setParameters')"
            placement="top"
            :open-delay="1000"
            v-has="'set'"
          >
            <el-button type="text" class="btn-p0" @click="setItem(scope.row.id)">
              <i class="el-icon-setting iconfont icon-setting"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="$t('group.pushGroupConfiguration')"
            placement="top"
            :open-delay="1000"
            v-has="'push'"
          >
            <el-button type="text" class="btn-p0" @click="pushItem(scope.row)">
              <i class="el-icon-s-promotion iconfont icon-push"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="$t('group.editGroupTemplate')"
            placement="top"
            :open-delay="1000"
            v-has="'edit'"
          >
            <el-button type="text" class="btn-p0" @click="editItem(scope.row.id)">
              <i class="el-icon-edit iconfont icon-edit"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="$t('downloadConfigurationfile')"
            placement="top"
            :open-delay="1000"
            v-has="'download'"
          >
            <el-button type="text" class="btn-p0" @click="downloadItemForModel(scope.row)">
              <i class="el-icon-download"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </div>
    <!-- 推送任务 -->
    <dialog-configuration-push
      v-if="pushModelVisible"
      type="group"
      :title="pushModelTitle"
      :visible.sync="pushModelVisible"
      :req="pushModelReq"
      :toolsDisplay="{
        modelShow: true,
        siteShow: true,
        keywordsShow: true,
      }"
      @scheduled="scheduledChange"
    ></dialog-configuration-push>
    <!-- 推送定时任务 -->
    <dialog-scheduled-update
      v-if="scheduledUpdateVisible"
      :visible.sync="scheduledUpdateVisible"
      :req="scheduledUpdateReq"
    ></dialog-scheduled-update>
    <!-- 查询设备 -->
    <dailog-devices
      v-if="devicesVisible"
      :visible.sync="devicesVisible"
      :req="devicesReq"
    ></dailog-devices>
    <!-- 下载文件 -->
    <dialog-select-model
      v-if="selectModelVisible"
      :visible.sync="selectModelVisible"
      :req="selectModelReq"
    ></dialog-select-model>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TablePagination } from '@/components'
import { groupSearch, removeGroupBatch, downloadGroup } from '@/api/group'
import { downloadFile } from '@/plugins/methods'
import DialogConfigurationPush from '../dailog-push/dialog-configuration-push'
import DialogScheduledUpdate from '../dailog-push/dialog-scheduled-update'
import DailogDevices from './dailog-devices'
import mixinTable from '@/components/mixin/table'
import DialogSelectModel from '@/components/dialog-select-model'

export default {
  name: 'Group',
  mixins: [mixinTable],
  components: {
    TablePagination,
    DialogConfigurationPush,
    DialogScheduledUpdate,
    DailogDevices,
    DialogSelectModel,
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'updateTime',
        sortOrder: 'desc',
        keywords: null,
        site: []
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('group.groupName'),
          prop: 'groupName',
          minWidth: 200,
          sortable: 'custom',
        },
        {
          label: this.$t('group.groupDescription'),
          prop: 'groupDesc',
          minWidth: 200,
        },
        {
          label: this.$t('group.deviceNumber'),
          prop: 'deviceNumber',
          minWidth: 200,
          sortable: 'custom',
          slot: true,
        },
        {
          label: this.$t('dateModified'),
          prop: 'updateTime',
          minWidth: 200,
          sortable: 'custom',
          filter: 'dateFormat',
        },
      ],
      tableData: [],
      // 推送任务
      pushModelVisible: false,
      pushModelTitle: this.$t('group.pushGroupConfiguration'),
      pushModelReq: {},
      // 推送定时任务
      scheduledUpdateVisible: false,
      scheduledUpdateReq: {},
      // 查询设备
      devicesVisible: false,
      devicesReq: {},
      //下载文件
      selectModelVisible: false,
      selectModelReq: {},
    }
  },
  computed: {
    ...mapGetters('session', ['pageTabelHeight']),
  },
  created() {
    this.tableInit()
  },
  methods: {
    // 获取数据
    getTabelData() {
      groupSearch(this.tabelSearch)
        .then((res) => {
          this.tableData = res.data.result.rows // 表格数据
          this.tabelTotal = res.data.result.totalRows // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 增
    addItem() {
      this.$router.push('/group/addGroup')
    },
    // 编辑
    editItem(id) {
      this.$router.push({ path: '/group/editGroup', query: { id } })
    },
    // 设置设备参数
    setItem(id) {
      this.$router.push({
        path: '/group/setParam',
        query: { relationName: 'group', id },
      })
    },
    // 下载
    downloadItemForModel(row) {
      console.log('downloadItemForModel')
      this.selectModelVisible = true
      this.selectModelReq.data = row
    },
    downloadItem(row) {
      downloadGroup({
        relationId: row.id,
        templateName: row.groupName,
      }).then((res) => downloadFile(res))
    },
    // 删
    deleteItem() {
      this.$confirm(this.$t('group.deleteTip'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          const ids = this.tableSelection.map((e) => e.id),
            args = this.tableSelection.map((e) => e.groupName)
          removeGroupBatch({ ids, args })
            .then((res) => {
              this.$message.success(res.data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 推送任务
    pushItem(row) {
      this.pushModelVisible = true
      this.pushModelReq.row = {
        id: row.id,
        relationName: 'group',
      }
    },
    // 推送定时任务
    scheduledChange(val) {
      this.scheduledUpdateVisible = true
      this.scheduledUpdateReq.data = val
    },
    // 查询设备
    deviceDetails(row) {
      this.devicesVisible = true
      this.devicesReq.data = row
    },
  },
}
</script>

<style lang="scss" scoped>
 
</style>