<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <table-pagination
      :tableData="tableData"
      :columnData="columnData"
      :total="tabelTotal"
      :currentPage.sync="tabelSearch.currentPage"
      :pageSize.sync="tabelSearch.pageSize"
      @changeCurrentPage="getDeviceData"
    ></table-pagination>
    <span slot="footer">
      <el-button @click="handleClose">{{ $t('close') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { TablePagination } from '@/components'
import { groupDeviceDisplay } from '@/api/group'

export default {
  name: 'dailog-devices',
  components: {
    TablePagination,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('device.deviceDetails')
      },
    },
    req: Object,
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'updateTime',
        sortOrder: 'desc',
        groupTemplateId: this.req.data.id,
        site: [],
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('device.macAddress'),
          prop: 'macAddress',
          minWidth: 150,
          filter: 'toUpperCase',
        },
        {
          label: this.$t('device.deviceName'),
          prop: 'deviceName',
          minWidth: 150,
        },
        {
          label: this.$t('device.model'),
          prop: 'deviceModel',
          minWidth: 100,
        },
        {
          label: this.$t('sipAccount.userName'),
          prop: 'userName',
          minWidth: 120,
        },
      ],
      tableData: [],
    }
  },
  created() {
    this.getDeviceData()
  },
  methods: {
    // 查询设备
    getDeviceData() {
      groupDeviceDisplay(this.tabelSearch)
        .then(({ data }) => {
          this.tableData = data.result.rows // 表格数据
          this.tabelTotal = data.result.totalRows // 表格数据条目
        })
        .catch(() => {})
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
  },
}
</script>
